<template>
	<div class="rightMeun">
		<!-- {{meuns[0]}}12 -->
		<!-- {{submeuns}}12 -->
		<div class="mbx">
			<div class="mbx-title">
				<!-- <router-link to="/">首页></router-link>
				<span @click="nato" v-for="(item, index) in title" :key="index">{{ item }}></span> -->
				<span>首页></span>
				<span v-for="(item, index) in title" :key="index">{{ item }}></span>
				<span v-if="submeuns.length && meuns.length">{{ meuns[act].title }}</span>
				<span v-else>
					<span v-if="meuns.length">{{ meuns[act].title }}</span>
					<span v-else></span>
				</span>
				<!-- <span v-if='submeuns.length'> {{submeuns[acts].name}}</span> -->
			</div>
			<div class="mbx-line"></div>
		</div>

		<div v-if="submeuns.length > 0">
			<div class="reservat"
				v-if="!isThree && type == 'newscategory' && submeuns[0].pid == arctype.FU_WU_ZHAN_TING">
				<!-- <div class="content" v-for="(li, index) in submeuns" :key="index">
					<a href="javascript:;" @click="nato(li, index, index)">
						<img src="../assets/img/xintp/wb.png" alt="" v-if="li.id == 121" />
						<img src="../assets/img/xintp/bx.png" alt="" v-else />
					</a>
				</div> -->
				<!-- "https://musahc.sustech.edu.cn/details/text/7?index=1&pid=7&sx=1&catid=160&type=page&isThree=0&page=1&show_tile=1" -->
				<!-- http://192.168.0.106:8080/details/text/7?index=1&pid=7&sx=1&catid=160&type=page&isThree=0&page=1&show_tile=1 -->
				<div class="content">

					<a
						href="https://musahc.sustech.edu.cn/details/text/7?index=1&pid=7&sx=1&catid=160&type=page&isThree=0&page=1&show_tile=1">
						<!-- <a
						href="http://192.168.0.106:8080/details/text/7?index=1&pid=7&sx=1&catid=160&type=page&isThree=0&page=1&show_tile=1"
					> -->
						<img src="../assets/img/xintp/wb.png" alt="" />
					</a>
				</div>
				<!-- href="http://192.168.0.106:8080/details/text/7?index=1&pid=7&sx=1&catid=176&type=page&isThree=1&page=1&show_tile=1" -->
				<!-- href="https://musahc.sustech.edu.cn/details/text/7?index=1&pid=7&sx=1&catid=176&type=page&isThree=1&page=1&show_tile=1" -->
				<div class="content">
					<a
						href="https://musahc.sustech.edu.cn/details/text/7?index=1&pid=7&sx=1&catid=176&type=page&isThree=1&page=1&show_tile=1">
						<!-- <a
						href="http://192.168.0.106:8080/details/text/7?index=1&pid=7&sx=1&catid=176&type=page&isThree=1&page=1&show_tile=1"
					> -->
						<img src="../assets/img/xintp/bx.png" alt="" />
					</a>
				</div>
			</div>

			<div class="subMeun" v-else>
				<ul>
					<li v-for="(li, index) in submeuns" style="padding: 5px; cursor: pointer" :key="index"
						@click="nato(li, index, isThree)" :class="[li._current ? 'on_1' : '']">
						{{ li.title }}
						<!-- <view :class="{'on':acts==index}" style="witdth:100%;height:100px;"  v-if="li.type=='news'" :to='"/bggk/listGk/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&chickid="+ li.id+"&type=news"'>{{li.title}}{{li.lv}}</view> -->
						<!-- <router-link :class="{'on':acts==index}"  v-if="li.type=='news'" :to='"/bggk/listGk/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&chickid="+ li.id+"&type=news"'>{{li.title}}{{li.lv}}</router-link>
				<router-link :class="{'on':acts==index}"  v-else-if="li.type=='page'" :to='"/bggk/listGk/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&type=page"'>{{li.title}}</router-link> -->
					</li>
				</ul>
				<!-- <span v-for="(li,index) in submeuns" :key='index'> -->
				<!-- {{li}} -->
				<!--
				<router-link :class="{'on':acts==index}"  v-if="li.iscategory" :to='"/bggk/listGk/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&catid="+ li.fid+"&type=newscategory"'>{{li.name}}</router-link>
				<router-link :class="{'on':acts==index}"  v-else :to='"/bggk/listGk/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&catid="+ li.fid+"&type=news"'>{{li.name}}</router-link> -->
				<!-- <router-link :class="{'on':acts==index}"  v-if="li.type=='page'" :to='"/details/text/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&catid="+ li.catid+"&type="+li.type'>{{li.name}}</router-link>
				<router-link :class="{'on':acts==index}"  v-if="li.type=='form'" :to='"/fwdt/yyjyF/"+li.id+"?index="+meun+"&sx="+act+"&sx1="+index+"&pid="+pid+"&catid="+ li.catid+"&type="+li.type'>{{li.name}}</router-link> -->
				<!-- <router-link v-if="li.children[0].type=='page'" :to="'/details/text/'+li.children[0].id+'?index='+index+'&pid='+li.id+'&page=1&catid='+ li.children[0].catid" class="header_nav_li_a">{{li.title}}</router-link>
				<router-link v-if="li.children[0].type=='form'" :to="'/fwdt/yyjyF/'+li.children[0].id+'?index='+index+'&pid='+li.id" class="header_nav_li_a">{{li.title}}</router-link> -->
				<!-- </span> -->
			</div>
		</div>
	</div>
</template>

<script>
import QueryString from 'qs';
export default {
	data() {
		return {
			act: this.$route.query.sx || 0,
			acts: this.$route.query.sx1 || 0,
			pid: this.$route.query.pid, //上一级的pid
			meun: this.$route.query.index, //选取的一级菜单
			meuns: [],
			submeuns: [],
			names: this.$route.query.names,
			id: 0,
			//选中样式
			onItemIndex: 0,
			title: [],
			type: '',
			isThree: false,
		};
	},
	props: ['menuid', 'name'],
	computed: {},
	watch: {
		$route: {
			handler: function (val) {
				// this.$forceUpdate();
				this.getSubMenus();
				//console.warn(this.$store.state.navIndexs);
			},
			// 深度观察监听
			deep: true,
		},
	},
	methods: {
		getSubMenus() {
			let { query } = this.$route;
			if (query.type) {
				this.type = query.type
			}
			let { navList, navIndexs } = this.$store.state;
			if (
				query.index == 1 &&
				query.pid == 7 &&
				query.sx == 1 &&
				query.type == 'newscategory' &&
				query.show_tile == '1'
			) {
				query.catid = 0;
			}
			// console.log(navIndexs);
			if (query.index) {
				navIndexs[0] = query.index;
			}
			this.isThree = query.isThree;
			// console.log(',,', navList[navIndexs[0]]);
			this.submeuns = navList[navIndexs[0]] && navList[navIndexs[0]].children ? (navList[navIndexs[0]].children[navIndexs[1]] ? navList[navIndexs[0]].children[navIndexs[1]].children : []) : [];

			//判断文博菜单列表
			if ((navIndexs[0] == 1 && navIndexs[1] == 1 && navIndexs[2] == 1 && navIndexs[3] == 2 && query.type == 'bggk') || (navIndexs[0] == 1 && navIndexs[1] == 1 && navIndexs[2] == 0 && navIndexs[3] == 2 && query.type == 'bggk')) {
				this.submeuns = [];
			}

			//存在子栏目
			this.id = this.$route.params.newsid; //获取id
			let titles = navList[navIndexs[0]] ? [navList[navIndexs[0]].title, navList[navIndexs[0]].children[navIndexs[1]].title] : '';
			if (query.catid == 160 || query.catid == 122 || query.catid == 159 || query.catid == 158) {
				//文博中心
				navIndexs[2] = 0;
				titles.push('文博中心');
			}
			if (query.catid == 176 || query.catid == 173 || query.catid == 175 || query.catid == 174) {
				//
				navIndexs[2] = 1;
				titles.push('办学成功展厅');
			}
			if (this.submeuns.length > 0) {
				this.submeuns.map((item, index) => {
					item._current = false;
					if (query.catid == item.id) {
						item._current = true;
						navIndexs[2] = this.isThree ? this.isThree : index;
						// console.log(item);
						titles.push(item.title);
					}
					return item;
				});
				// console.log("this.submeuns11",this.submeuns)
				if (this.isThree) {
					if (this.submeuns[navIndexs[2]] && this.submeuns[navIndexs[2]].children) {
						this.submeuns = this.submeuns[navIndexs[2]].children;
					}
				}
				// console.log("this.submeuns",this.submeuns)

				//console.log(navList, navIndexs);
				this.$store.dispatch('save', ['navList', navList]);
				this.$store.dispatch('save', ['navIndexs', navIndexs]);
			}
			// console.log(titles)
			this.title = titles;
		},
		navLiClick(index) {
			// let current
			let data = this.submeuns[index];
			this.onItemIndex = index;
			// if(data.type=='page'){
			// }
			// let data = {};
			this.$emit('render', { data });
		},
		nato(e, index, isThree) {
			// console.log(e,index,isThree);
			let { navList, navIndexs } = this.$store.state;
			//if (index == navIndexs[2]) return;
			let leftMenu = navList[navIndexs[1]];
			let params = {
				index: navIndexs[0],
				pid: navList[navIndexs[0]].id,
				sx: navIndexs[1],
				catid: e.id,
				type: e.type,
				isThree,
			};
			let url = '';
			let id = leftMenu.id;
			if (params.type == 'newscategory' && e.children.length > 0) {
			}
			if (params.type == 'news' || params.type == 'newscategory') {
				url = '/bggk/listGk/';
			} else if (params.type == 'form') {
				delete params.type;
				delete params.catid;
				url = '/fwdt/yyjyF/';
			} else if (params.type == 'page') {
				params.page = 1;
				params.show_tile = e.is_show_title;
				url = '/details/text/';
			}
			url += id + '?' + QueryString.stringify(params);
			// console.warn('______________url', url);
			// console.log(url, params);
			let cateIndex = 2;
			if (isThree) cateIndex = 3;
			navIndexs[cateIndex] = index;
			this.$store.dispatch('save', ['navIndexs', navIndexs]);
			this.$router.push(url);
		},
	},
	created() {
		this.$forceUpdate();
		this.getSubMenus();
	},
};
</script>

<style>
.on_1 {
	font-weight: bold;
	border-bottom: 3px solid #26b6b4;
	color: #26b6b4;
}

.reservat {
	padding: 80px 0;
	display: flex;
	justify-content: space-around;
}

.reservat .content {
	width: 100%;
}

.reservat .content img {
	width: 96%;
}

.reservat .content .title {
	font-size: 18px;
	font-weight: bold;
}
</style>
