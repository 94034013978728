<template>
	<div class="newright">
		<div class="content">
			<div class="newright-imgs">
				<div v-for="(item, index) in news" class="img" :key="index">
					<a v-if="item.jump == 'url'" :href="item.url" :title="item.title" target="_blank">
						<div class="title">{{ item.title }}</div>
						<div class="time">{{ item.timeline }}</div>
					</a>
					<router-link v-else :to="'/details/cont/' + item.id" :title="item.title">
						<img v-if="item.thumb" :src="item.thumb" alt="" />
						<img v-else :src="imageTP" alt="" />
					</router-link>
				</div>
			</div>

			<div class="content-title">
				<div class="title-list" v-for="(item, index) in dynamic" :key="index">
					<a v-if="item.jump == 'url'" :href="item.url" :title="item.title" target="_blank">
						<div class="title">{{ item.title }}</div>
						<div class="time">{{ item.timeline }}</div>
					</a>
					<router-link v-else :to="'/details/cont/' + item.id" :title="item.title">
						<div class="title">{{ item.title }}</div>
						<div class="time">{{ item.timeline }}</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imageTP: this.$api.getUrl('/files/images/tp.jpg'),
			news: [],
			dynamic: [],
		};
	},

	name: 'newRightMeun',
	props: {
		index: {
			type: String,
			default: '',
		},
	},
	watch: {
		$route: {
			handler: function (val) {
				this.loadData();
			},
			// 深度观察监听
			deep: true,
		},
	},
	mounted() {
		this.loadData();
	},

	methods: {
		loadData() {
			let { navList, navIndexs } = this.$store.state;
			let index = navIndexs[0];
			if (index < 0 || index > navList.length) {
				index = 0;
			}
			this.$api
				.rightmeun({ id: navList[index].id })
				.then((res) => {
					this.$emit('getLoad', true);
					this.news = this.getArrayItems(res.img_data, 4); //图文
					this.dynamic = this.getArrayItems(res.text_data, 2); // 文字
				})
				.catch((error) => {
					// error
					console.log(error);
				});
		},
		getArrayItems(arr, num) {
			//console.log("arr", arr);
			//新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组;
			var temp_array = new Array();
			for (var index in arr) {
				temp_array.push(arr[index]);
			}
			//取出的数值项,保存在此数组
			var return_array = new Array();
			for (var i = 0; i < num; i++) {
				//判断如果数组还有可以取出的元素,以防下标越界
				if (temp_array.length > 0) {
					//在数组中产生一个随机索引
					var arrIndex = Math.floor(Math.random() * temp_array.length);
					//将此随机索引的对应的数组元素值复制出来
					return_array[i] = temp_array[arrIndex];
					//然后删掉此索引的数组元素,这时候temp_array变为新的数组
					temp_array.splice(arrIndex, 1);
				} else {
					//数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
					break;
				}
			}
			return return_array;
		},
	},
	created() { },
};
</script>

<style></style>
