<template>
	<div class="leftMeun">
		<div class="leftT">
			<span v-if="navList[navIndexs[0]]">{{ navList[navIndexs[0]].title }}</span>
			<span v-else>首页 </span>
			<!-- {{ navList[navIndexs[0]] == -1 ? '首页' : navList[navIndexs[0]].title }} -->
		</div>
		<p :class="{ on: li._current }" @click="nato(li, index)" v-for="(li, index) in meuns" :key="index">
			{{ li.title }}
		</p>
		<div class="imgs" style="justify-content: center">
			<a href="http://www.sustech.edu.cn/zh/academic-calendar.html" target="_blank">
				<img :src="imageTP" alt="" /></a>
		</div>
		<div class="search hidden">
			<input type="text" @keydown="keydown" v-model="searchkeyword" placeholder="SEARCH" />
			<img @click="onSearch" src="../assets/img/xintp/ser.png" alt="" />
		</div>
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import QueryString from 'qs';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			imageTP: this.$api.getUrl('/files/images/tp.jpg'),
			act: this.$route.query.sx || 0,
			searchkeyword: '',
			meuns: [],
			meun: this.$route.query.index,
			pid: this.$route.query.pid,
			submeuns: [],
			acts: '',
			names: this.$route.query.names
		};
	},
	props: ['menuid'],
	computed: {
		...mapState(['navList', 'navIndexs'])

		// title() {
		// 	console.log('二级菜单', this.$store.state.navList[this.$store.state.navIndexs[0]].title);
		// 	this.$forceUpdate();
		// 	if (this.$store.state.navIndexs[0] == -1) {
		// 		return '首页';
		// 	} else {
		// 		return this.$store.state.navList[this.$store.state.navIndexs[0]].title;
		// 	}
		// },
	},
	watch: {
		$route: {
			handler: function(val) {
				var href = val.query;
				this.act = href.sx || 0;
				this.meun = href.index;
				this.pid = href.pid;
				this.$forceUpdate();
				this.getMenus()
					.then(res => {
						this.$forceUpdate();
					})
					.catch(e => {});
			},
			// 深度观察监听
			deep: true
		}
	},

	created() {
		this.getMenus();
	},
	methods: {
		getMenus() {
			return new Promise((resolve, reject) => {
				let { query } = this.$route;

				let active = query.sx || 0;
				let { navList, navIndexs } = this.$store.state;
				if (query.index) {
					this.navIndexs[0] = query.index;
				}
				let current = [];
				if (navIndexs[0] >= 0) {
					current = navList[navIndexs[0]].children;
					navIndexs[1] = active < current.length ? active : 0;
					current.map((item, index) => {
						item._current = index == navIndexs[1];
						return item;
					});
				}
				this.meuns = current;
				this.$store.dispatch('save', ['navList', navList]);
				this.$store.dispatch('save', ['navIndexs', navIndexs]);
				resolve();
			});
		},
		keydown(event) {
			var that = this;
			console.log(event);
			if (event.which == 13) {
				that.onSearch();
			}
		},
		nato(e, index1) {
			if (e.__target != '_blank') {
				this.$router.push(e.__url);
			} else {
				window.open(e.__url, '_blank');
			}
			return;
			let { navList, navIndexs } = this.$store.state;
			//if (index1 == navIndexs[1]) return;

			let params = {
				index: navIndexs[0],
				pid: navList[navIndexs[0]].id,
				sx: index1,
				catid: e.id,
				type: e.type
			};
			let url = '';
			let id = e.id;
			let exitsChildren = e.children && e.children.length > 0;
			if (exitsChildren) {
				params.catid = e.children[0].id;
				params.type = e.children[0].type;
			}
			console.log('params.typeparams.typeparams.type', params.type);
			console.log('____________id', id);
			params.id = id;
			if (params.type == 'news') {
				url = '/bggk/listGk/';
			} else if (params.type == 'form') {
				delete params.type;
				url = '/fwdt/yyjyF/';
			} else if (params.type == 'page') {
				params.show_tile = e.is_show_title;
				params.page = 1;
				url = '/details/text/';
			}
			url += id + '?' + QueryString.stringify(params);
			console.log(url, params);
			this.$router.push(url);
			// if (e.type == 'newscategory') {
			// 	this.$api
			// 		.category({
			// 			fid: e.fid,
			// 		})
			// 		.then((res) => {
			// 			if (res.data.length) {
			// 				this.$router.push('/bggk/listGk/' +res.data[0].id +'?index=' +this.meun +'&pid=' +this.pid +'&sx=' +index1 +'&catid=' +e.catid +'&type=' +e.type);
			// 			} else {
			// 				this.$router.push('/bggk/listGk/' +res.id +'?index=' +this.meun +'&pid=' +this.pid +'&sx=' +index1 +'&catid=' +e.catid +'&type=' +e.type);
			// 			}
			// 		})
			// 		.catch((error) => {
			// 			// error
			// 			console.log(error);
			// 		});
			// }
			// if (e.type == 'form') {
			// 	this.$router.push('/fwdt/yyjyF/' + e.id + '?index=' + this.meun + '&pid=' + this.pid + '&sx=' + index1);
			// }
			// if (e.type == 'news') {
			// 	this.$router.push(
			// 		'/bggk/listGk/' +e.id +'?index=' +this.meun +'&pid=' +this.pid +'&sx=' +index1 +'&catid=' +e.catid +'&type=' +e.type
			// 	);
			// }

			// if (e.type == 'page') {
			// 	this.$router.push(
			// 		'/details/text/' +e.id +'?index=' +this.meun +'&pid=' +this.pid +'&sx=' +index1 +'&page=1&catid=' +e.catid
			// 	);
			// }
		},
		onSearch() {
			var that = this;
			if (!that.searchkeyword) {
				that.$Message.error('请输入搜索词！');
				return;
			}
			that.$router.push({
				name: 'search',
				params: {
					searchtext: that.searchkeyword
				}
			});
		}
	}
};
</script>

<style></style>
